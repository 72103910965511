import * as React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { AuthContext } from '../../App'
import UserLoggedInterface from '../../types/User/UserLoggedInterface'
import Registration from "../registration/Registration"
import Login from "../login/Login"
import ResetPasswordRequest from "../resetPasswordRequest/ResetPasswordRequest"
import ResetPassword from "../resetPassword/ResetPassword"
import PrivateRoute from "../../components/privateRoute/PrivateRoute"
import Navbar from '../../components/navbar/Navbar'
import ChangePasswordPage from "../changePassword/ChangePassword"
import MyProfile from "../myProfile/MyProfile"
import EditProfile from "../editProfile/EditProfile"
import UsersPage from "../users/Users"
import UserViewProfile from "../viewProfile/UserViewProfile"
import FaqAdministration from "../faqAdministration/FaqAdministration"
import AddFaqItemPage from "../addFaqItem/AddFaqItem"
import EditFaqItem from "../editFaqItem/EditFaqItem"
import ViewFaqItem from "../viewFaqItem/ViewFaqItem"
import FaqPage from '../faq/FaqPage'
import { toast } from 'react-toastify'
import NotFoundPage from '../notFound/NotFoundPage'
import AdvertisementMainPage from "../advertisementMainPage/AdvertisementMainPage"
import Footer from '../../components/footer/Footer'
import ShowAdvertisementsPage from "../advertisementCategory/ShowAdvertisementsPage"
import AddAdvertisementPage from '../addAdvertisement/AddAdvertisementPage'
import EditAdvertisementPage from "../editAdvertisement/EditAdvertisementPage"
import MyAdvertisementsPage from "../myAdvertisementsPage/MyAdvertisementsPage"
import ShowAdvertisementPage from "../showAdvertisement/ShowAdvertisementPage"
import AddFeaturePage from '../users/AddFeature'
import AddPackage from '../users/AddPackage'
import OrderPage from "../order/OrderPage"
import CreatePaymentPage from '../payment/CreatePaymentPage'
import PaymentSuccessPage from '../payment/PaymentSuccessPage'
import PaymentCheckStatusPage from '../payment/PaymentCheckStatusPage'
import PaymentCancelledPage from '../payment/PaymentCancelledPage'
import PaymentInProgressPage from "../payment/PaymentInProgressPage"
import AdvertisementCreatedPickPackagePage from '../AdvertisementCreatedPickPackage/AdvertisementCreatedPickPackagePage'
import AdvertisementSetAsSoldPage from '../advertisementSetAsSold/AdvertisementSetAsSoldPage'
import AddUserPage from '../addUser/AddUserPage'
import UserNotEnabledPage from '../userNotEnabled/UserNotEnabledPage'
import Loading from '../../components/loader/Loading'
import GdprPage from '../general/GdprPage'
import GeneralTermsPage from "../general/GeneralTermsPage"
import OrdersProPage from "../ordersPro/OrdersProPage"
import CreatePaymentByOrderPage from "../order/CreatePaymentByOrderPage"
import OrderCreatedPage from "../order/OrderCreatedPage"
import PaymentSendNotificationsPage from '../payment/PaymentSendNotificationsPage'
import LoggedOutForInactivityPage from "../loggedOutForInactivity/LoggedOutForInactivityPage";

const ScrollToTop = () => {
    const { pathname } = useLocation()
    React.useEffect(() => {
        window.scrollTo(0, 0) // Scroll to the top of the page whenever the pathname changes
    }, [pathname])

    return null // This component doesn't render anything
}

export default function IndexPage(): JSX.Element {
    // constants
    const USER_SUCCESSFULLY_LOGGED_OUT: string = 'Používateľ bol úspešne odhlásený!'
    const INACTIVITY_LIMIT: number = 10800 // 3 hours in seconds
    // context
    const authContext = React.useContext(AuthContext)
    // state
    const [userLogged, setUserLogged] = React.useState<UserLoggedInterface | null>(null)
    const [isUserLoggedIn, setIsUserLoggedIn] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [timeOnPage, setTimeOnPage] = React.useState(0) // Time in seconds

    //--  effect - check if user is logged in and set userLogged
    React.useEffect(() => {
        const checkIfUserIsLoggedAndSetUserLogged = async () => {
            const isUserLoggedIn = await authContext.isUserSignedIn()
            if (isUserLoggedIn) {
                const userLogged = await authContext.getUserLogged() // nastavi sa v contexte userLogged
                setUserLogged(userLogged)
            }
            setIsUserLoggedIn(isUserLoggedIn)
        }
        checkIfUserIsLoggedAndSetUserLogged()
    }, [authContext.jwtToken])

    React.useEffect(() => {
        if (userLogged !== null) {
            //ak je user prihlaseny a location nie je '/change-password'
            if (window.location.pathname !== '/change-password' && window.location.pathname !== '/user/not-enabled') {
                // a prihlaseny user nie je enabled, tak ho presmerujeme na '/user/not-enabled'
                if (!userLogged.enabled) {
                    window.location.href = '/user/not-enabled'
                }
            }
        }
        setIsLoading(false)
    }, [userLogged])

    React.useEffect(() => {
        // Function to reset the timer on user activity
        const resetTimer = () => {
            const lastActivityTime = localStorage.getItem("lastActivityTime") !== null ? parseInt(localStorage.getItem("lastActivityTime")!) : null
            const currentTime = Date.now()

            // Check if the user has been inactive for INACTIVITY_LIMIT
            if (lastActivityTime && currentTime - lastActivityTime >= INACTIVITY_LIMIT * 1000) {
                setIsUserLoggedIn(false)
                setUserLogged(null)
                authContext.logout(true)
            } else {
                // Update the last activity time
                localStorage.setItem("lastActivityTime", currentTime.toString())
                // Reset the time on page
                setTimeOnPage(0)
            }
        }

        const handleUnload = () => {
            // check if user is inactive for INACTIVITY_LIMIT
            const lastActivityTime = localStorage.getItem("lastActivityTime") !== null ? parseInt(localStorage.getItem("lastActivityTime")!) : null
            const currentTime = Date.now()
            if (lastActivityTime && currentTime - lastActivityTime >= INACTIVITY_LIMIT * 1000) {
                setIsUserLoggedIn(false)
                setUserLogged(null)
                authContext.logout(true)
            }
            localStorage.removeItem("lastActivityTime")
        }

        // Add event listeners to track user activity
        window.addEventListener("mousemove", resetTimer)
        window.addEventListener("keypress", resetTimer)
        window.addEventListener('unload', handleUnload)

        // Initialize the last activity time on page load
        localStorage.setItem("lastActivityTime", Date.now().toString())

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener("mousemove", resetTimer)
            window.removeEventListener("keypress", resetTimer)
            window.removeEventListener('unload', handleUnload)
        }
    }, [])

    //-- count how long is user on a page
    React.useEffect(() => {
        const interval = setInterval(() => {
            setTimeOnPage((prevTime) => prevTime + 1)
        }, 1000)

        // Cleanup interval on component unmount
        return () => clearInterval(interval)
    }, [])

    React.useEffect(() => {
        if (timeOnPage >= INACTIVITY_LIMIT) { // vid. INACTIVITY_LIMIT
            setIsUserLoggedIn(false)
            setUserLogged(null)
            authContext.logout(true)
        }
    }, [timeOnPage])

    // handlers
    const handleLogout = (): void => {
        authContext.logout()
        setIsUserLoggedIn(false)
        setUserLogged(null)
        toast.success(USER_SUCCESSFULLY_LOGGED_OUT)
    }

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    return (
        <React.Fragment>
            <ScrollToTop/>
            <div className='wrapper'>
                <Navbar isLoading={isLoading}
                        isUserLoggedIn={isUserLoggedIn}
                        userLogged={userLogged}
                        handleLogout={handleLogout}
                />
                <Routes>
                    {/* Home Page - tu budu inzeraty atd... */}
                    <Route path='/' element={<AdvertisementMainPage/>}/>
                    <Route path='/advertisements' element={<ShowAdvertisementsPage/>}/>
                    {/* Inzerat */}
                    <Route path='/advertisement/view/:id' element={<ShowAdvertisementPage/>}/>
                    {/* Pridat inzerat */}
                    <Route path='/advertisement/add' element={<PrivateRoute/>}>
                        <Route path='/advertisement/add' element={<AddAdvertisementPage/>}/>
                    </Route>
                    <Route path='/advertisement/created/pickPackage/:advertisementId' element={<PrivateRoute/>}>
                        <Route path='/advertisement/created/pickPackage/:advertisementId'
                               element={<AdvertisementCreatedPickPackagePage/>}/>
                    </Route>
                    <Route path='/advertisement/setAsSold/:advertisementId'
                           element={<AdvertisementSetAsSoldPage/>}/>
                    {/* Editovat inzerat */}
                    <Route path='/advertisement/edit/:id' element={<PrivateRoute/>}>
                        <Route path='/advertisement/edit/:id' element={<EditAdvertisementPage/>}/>
                    </Route>
                    {/* Moje inzeraty */}
                    <Route path='/my-advertisements' element={<PrivateRoute/>}>
                        <Route path='/my-advertisements' element={<MyAdvertisementsPage/>}/>
                    </Route>
                    {/* Registracia, prihlasenie */}
                    <Route path='/registration' element={<Registration/>}/>
                    <Route path='/login' element={<Login/>}/>
                    {/* Reset hesla, zmena hesla */}
                    <Route path='/reset-password-request' element={<ResetPasswordRequest/>}/>
                    <Route path='/reset-password/:token' element={<ResetPassword/>}/>
                    <Route path='/change-password' element={<PrivateRoute/>}>
                        <Route path='/change-password' element={<ChangePasswordPage/>}/>
                    </Route>
                    {/* Profil, list pouzivatelov, editacia pouzivatela */}
                    <Route path='/my-profile' element={<PrivateRoute/>}>
                        <Route path='/my-profile' element={<MyProfile/>}/>
                    </Route>
                    <Route path='/edit-profile/:id' element={<PrivateRoute/>}>
                        <Route path='/edit-profile/:id' element={<EditProfile/>}/>
                    </Route>
                    <Route path='/addFeature/:id' element={<PrivateRoute/>}>
                        <Route path='/addFeature/:id' element={<AddFeaturePage/>}/>
                    </Route>
                    <Route path='/addPackage/:id' element={<PrivateRoute/>}>
                        <Route path='/addPackage/:id' element={<AddPackage/>}/>
                    </Route>
                    <Route path='/users' element={<PrivateRoute/>}>
                        <Route path='/users' element={<UsersPage/>}/>
                    </Route>
                    <Route path='/user/view-profile/:id' element={<PrivateRoute/>}>
                        <Route path='/user/view-profile/:id' element={<UserViewProfile/>}/>
                    </Route>
                    <Route path='/add-user' element={<PrivateRoute/>}>
                        <Route path='/add-user' element={<AddUserPage/>}/>
                    </Route>
                    {/* FAQ Administrácia */}
                    <Route path='/administration-faq' element={<PrivateRoute/>}>
                        <Route path='/administration-faq' element={<FaqAdministration/>}/>
                    </Route>
                    <Route path='/add/faq-item' element={<PrivateRoute/>}>
                        <Route path='/add/faq-item' element={<AddFaqItemPage/>}/>
                    </Route>
                    <Route path='/edit/faq-item/:id' element={<PrivateRoute/>}>
                        <Route path='/edit/faq-item/:id' element={<EditFaqItem/>}/>
                    </Route>
                    <Route path='/view/faq-item/:id' element={<PrivateRoute/>}>
                        <Route path='/view/faq-item/:id' element={<ViewFaqItem/>}/>
                    </Route>
                    {/* FAQ - pre hociktoreho pouzivatela - t.j. aj pre nie logged in usera */}
                    <Route path='/faq' element={<FaqPage/>}/>
                    {/* Objednavkovy formular + platby */}
                    <Route path='/order/:packageId' element={<PrivateRoute/>}>
                        <Route path='/order/:packageId/:advertisementId' element={<OrderPage/>}/>
                    </Route>
                    <Route path='/orders-pro' element={<PrivateRoute/>}>
                        <Route path='/orders-pro' element={<OrdersProPage/>}/>
                    </Route>
                    <Route path='/createPayment/byOrder/:orderId' element={<PrivateRoute/>}>
                        <Route path='/createPayment/byOrder/:orderId' element={<CreatePaymentByOrderPage/>}/>
                    </Route>
                    <Route path='/order-created/:orderId' element={<PrivateRoute/>}>
                        <Route path='/order-created/:orderId/:advertisementId' element={<OrderCreatedPage/>}/>
                    </Route>
                    {/* Platby */}
                    <Route path='/createPayment/:paymentId' element={<PrivateRoute/>}>
                        <Route path='/createPayment/:paymentId' element={<CreatePaymentPage/>}/>
                    </Route>
                    <Route path='/payment/success/:paymentId/:advertisementId' element={<PaymentSuccessPage/>} />
                    <Route path='/payment/cancelled/:paymentId/:advertisementId' element={<PaymentCancelledPage/>} />
                    <Route path='/payment/in-progress/:paymentId/:advertisementId' element={<PaymentInProgressPage/>} />
                    <Route path='/payment/check/status'
                           element={<PaymentCheckStatusPage/>}/>
                    <Route path='/payment/send/notifications' element={<PaymentSendNotificationsPage/>} />
                    {/* General - cookies, gdpr, general terms */}
                    {/* zakomentovane na ziadost M 02.10.2023 - nieje zatial pripravena stranka na cookies */}
                    {/*<Route path='/cookies' element={<CookiesPage/>}/>*/}
                    <Route path='/gdpr' element={<GdprPage/>}/>
                    <Route path='/general-terms-and-conditions' element={<GeneralTermsPage/>} />
                    {/* Tu usera redirectne ked nie je enabled */}
                    <Route path='/user/not-enabled' element={<PrivateRoute/>}>
                        <Route path='/user/not-enabled' element={<UserNotEnabledPage/>} />
                    </Route>
                    {/* logged out for in-activity */}
                    <Route path='/logged-out-for-inactivity' element={<LoggedOutForInactivityPage/>} />
                    {/* Not found */}
                    <Route path='/notfound' element={<NotFoundPage/>} />
                    <Route path='/*' element={<NotFoundPage/>} />
                </Routes>
            </div>
            <Footer/>
        </React.Fragment>
    )
}
