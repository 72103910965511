import {Container, Typography} from '@mui/material'
import * as React from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {AuthContext} from '../../App'
import UserInterface from "../../types/User/UserInterface"
import {getApiUrlFromRelativeUrl, request} from "../../utils/api/ApiUtil"
import {Action, ClientContext} from "react-fetching-library"
import {toast} from "react-toastify"
import {FeatureInterface} from '../../types/feature/FeatureInterface'
import {AdvertisementInterface} from "../../types/Advertisement/AdvertisementInterface"
import {DatePicker} from "@mui/x-date-pickers"
import {Dayjs} from "dayjs"
import CheckIcon from '@mui/icons-material/Check'
import dayjs from 'dayjs'
import Loading from "../../components/loader/Loading"
import ErrorComponent from "../../components/error/ErrorComponent"

export default function AddFeaturePage(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const COULDNT_LOAD_USER_MESSAGE: string = 'Nepodarilo sa načítať používateľa!'
    //- context
    const authContext = React.useContext(AuthContext)
    const clientContext = React.useContext(ClientContext)
    //- state
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [user, setUser] = React.useState<UserInterface | null>(null)
    const [features, setFeatures] = React.useState<FeatureInterface[]>([])
    const [advertisements, setAdvertisements] = React.useState<AdvertisementInterface[]>([])
    //- form data
    const [selectedFeatureId, setSelectedFeatureId] = React.useState<number | null>(null)
    const [selectedAdvertisementId, setSelectedAdvertisementId] = React.useState<number | null>(null)
    const [activeTill, setActiveTill] = React.useState<Date | null | Dayjs>(null)
    //- form errors
    const [selectedFeatureIdErrors, setSelectedFeatureIdErrors] = React.useState<string[]>([])
    const [selectedAdvertisementIdErrors, setSelectedAdvertisementIdErrors] = React.useState<string[]>([])
    const [activeTillErrors, setActiveTillErrors] = React.useState<string[]>([])
    const [isError, setIsError] = React.useState<boolean>(false)
    //- init params, navigate, get id from params
    const params = useParams()
    const navigate = useNavigate()
    const {id} = params

    //- actions
    const getUserAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/user/get/' + id),
        headers: {
            Authorization: 'Bearer ' + authContext.jwtToken,
            Accept: 'application/json'
        }
    }

    const getFeaturesAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/feature/getAll/notPaginated/1'), //- 0=show(false)
        headers: {
            Accept: 'application/json'
        }
    }

    const getAdvertisementsByUserAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/advertisements/all/byUser/notPaginated/${id}`),
        headers: {
            Authorization: `Bearer ${authContext.jwtToken}`,
            Accept: 'application/json'
        },
    }

    const saveFeatureXUserXAdvertisementAction: Action = {
        method: 'POST',
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/featureXUserXAdvertisementSet`),
        headers: {
            Authorization: `Bearer ${authContext.jwtToken}`,
            Accept: 'application/json'
        }
    }

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    //- effect - right
    React.useEffect(() => {
        if (authContext.userLogged === null) {
            navigate('/login')
        }
        //- right - user musi mat 'canSetFeatureXUserXAdvertisement=true'
        if (!authContext.userLogged?.canSetFeatureXUserXAdvertisement) {
            navigate('/users')
        }
    }, [authContext.userLogged])

    //- effect - nacitaj usera z backendu
    React.useEffect(() => {
        const getUser = async (): Promise<void> => {
            const {payload, error, status} = await request(getUserAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                if (status === 200) {
                    setUser(payload)
                }
            }
            if (status === 401) {
                // user sa nedokazal overit v api - skusime obnovit JWT token
                await authContext.refreshJWTToken()
            }
        }
        if (undefined !== id) {
            getUser()
        }
        //- POZOR: loading nechceme vypnut uz tu lebo, este niesu nacitane vsetky data, vid nizsie
    }, [id])

    //- effect - nacitaj features z backendu
    React.useEffect(() => {
        const getFeatures = async (): Promise<void> => {
            const {payload, error, status} = await request(getFeaturesAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                if (status === 200) {
                    setFeatures(payload)
                }
            }
            //- POZOR: loading nechceme vypnut uz tu lebo, este niesu nacitane vsetky data, vid nizsie
        }
        getFeatures()
    }, [])

    //- effect - nacitaj advertisements by user z backendu
    React.useEffect(() => {
        const getAdvertisementsByUser = async (): Promise<void> => {
            const {payload, error, status} = await request(getAdvertisementsByUserAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                if (status === 200 && payload) {
                    setAdvertisements(payload.data)
                }
            }
            if (status === 401) {
                // user sa nedokazal overit v api - odhlasime ho
                await authContext.refreshJWTToken()
            }
            //- loading vypneme az tu, lebo uz mame vsetky data
            setIsLoading(false)
        }
        getAdvertisementsByUser()
    }, [])

    //- on submit
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault()
        if (selectedFeatureId === null || selectedAdvertisementId === null || activeTill === null) {
            toast.error('Vyplňte všetky polia!')
            return
        }
        if (selectedAdvertisementId === 0 || selectedFeatureId === 0) {
            toast.error('Vyplňte všetky polia!')
            return
        }
        setActiveTillErrors([])
        setSelectedFeatureIdErrors([])
        setSelectedAdvertisementIdErrors([])
        setIsLoading(true)
        const saveFeatureXUserXAdvertisementAction2: Action = {
            ...saveFeatureXUserXAdvertisementAction,
            body: {
                userId: id !== undefined ? parseInt(id) : 0,
                featureId: selectedFeatureId,
                advertisementId: selectedAdvertisementId,
                activeTill: dayjs(activeTill).format('DD.MM.YYYY')
            }
        }
        const {payload, error, status} = await request(saveFeatureXUserXAdvertisementAction2, clientContext)
        if (error) {
            setIsError(true)
        } else {
            if (payload.success) {
                toast.success('Vlastnosť bola pridaná!')
                navigate('/users')
            } else {
                toast.error('Vlastnosť sa nepodarilo pridať!')
                for (let key in payload.errors) {
                    switch (key) { // takyto krepy switch zatial, mozno v buducnosti prepisat na nieco rozumnejsie
                        case 'feature':
                            setSelectedFeatureIdErrors([...payload.errors[key]])
                            break
                        case 'advertisement':
                            setSelectedAdvertisementIdErrors([...payload.errors[key]])
                            break
                        case 'activeTill':
                            setActiveTillErrors([...payload.errors[key]])
                            break
                        case 'user':
                            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
                            break
                        default:
                            break
                    }
                }
            }
            if (status === 401) {
                await authContext.refreshJWTToken()
            }
        }
        setIsLoading(false)
    }

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    //- render
    return (
        <React.Fragment>
            <Container sx={{mt: '3%', mb: '3%'}}>
                <Typography className='text-primary' variant='h6' style={{fontFamily: 'OpenSansBold'}}>
                    Pridať vlastnosť použivateľovi {user?.title} {user?.firstName} {user?.lastName}
                </Typography>
                <form onSubmit={onSubmit}>
                    <div className="form-group">
                        <label htmlFor="feature"
                               className={(selectedFeatureIdErrors.length > 0 ? 'error' : 'text-primary') + ' required'}
                               style={{fontFamily: 'OpenSansSemiBold'}}>
                            Vlastnosť
                        </label>
                        <select
                            className={`form-control ${selectedFeatureIdErrors.length > 0 ? 'error' : 'border-primary'} rounded-borders`}
                            id="feature" value={selectedFeatureId || 0}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedFeatureId(parseInt(e.target.value))}>
                            <option value={0}>
                                Vyberte vlastnosť
                            </option>
                            {features.map((feature: FeatureInterface) => (
                                <option key={feature.id} value={feature.id}>{feature.name}</option>
                            ))}
                        </select>
                        <div>
                            {selectedFeatureIdErrors.map((error: string, index: number) => (
                                <small key={index} className='text-danger'>{error}</small>
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="advertisement"
                               className={(selectedAdvertisementIdErrors.length > 0 ? 'error' : 'text-primary') + ' required'}
                               style={{fontFamily: 'OpenSansSemiBold'}}>
                            Inzerát
                        </label>
                        <select
                            className={`form-control ${selectedAdvertisementIdErrors.length > 0 ? 'error' : 'border-primary'} rounded-borders`}
                            id="advertisement" value={selectedAdvertisementId || 0}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSelectedAdvertisementId(parseInt(e.target.value))}>
                            <option value={0}>
                                Vyberte inzerát
                            </option>
                            {advertisements.map((advertisement: AdvertisementInterface) => (
                                <option key={advertisement.id} value={advertisement.id || 0}>
                                    {advertisement.subject}
                                </option>
                            ))}
                        </select>
                        <div>
                            {selectedAdvertisementIdErrors.map((error: string, index: number) => (
                                <small key={index} className='text-danger'>{error}</small>
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="activeTill" className={(activeTillErrors.length > 0 ? 'error' : 'text-primary') + ' required'}
                               style={{fontFamily: 'OpenSansSemiBold'}}>
                            Aktívny do
                        </label>
                        <DatePicker value={activeTill}
                                    className={`form-control ${activeTillErrors.length > 0 ? 'error' : 'border-primary'} rounded-borders`}
                                    onChange={(newDate) => setActiveTill(newDate)}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            variant: 'standard',
                                            InputProps: {
                                                disableUnderline: true,
                                            }
                                        }
                                    }}
                        />
                        <div>
                            {activeTillErrors.map((error: string, index: number) => (
                                <small key={index} className='text-danger'>{error}</small>
                            ))}
                        </div>
                    </div>
                    <div className="text-center mt-2">
                        <button className="btn btn-success">
                            <CheckIcon/>
                            Odoslať
                        </button>
                    </div>
                </form>
            </Container>
        </React.Fragment>
    )
}

