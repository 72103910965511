import * as React from 'react'
import { PackageInterface } from "../../types/package/PackageInterface"
import { FeatureInterface } from "../../types/feature/FeatureInterface"
import { Box, Card, CardContent, Container, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Action, ClientContext } from "react-fetching-library"
import { getApiUrlFromRelativeUrl, request } from "../../utils/api/ApiUtil"
import { toast } from 'react-toastify'
import Loading from "../loader/Loading"
import dayjs from "dayjs"
import ConfirmModal from "../modal/ConfirmModal"
import { AuthContext } from "../../App"
import { useNavigate } from "react-router-dom"

interface PackageCardProps {
    packageItem: PackageInterface,
    features: FeatureInterface[],
    advertisementId: number,
    userId: number, // userLogged id,
    jwtToken: string | null,
}

export default function PackageCard(props: PackageCardProps): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    //- state
    const [isActive, setIsActive] = React.useState<boolean>(false)
    const [hasActiveTill, setHasActiveTill] = React.useState<string | null>(null)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [autoRenewal, setAutoRenewal] = React.useState<boolean>(false)
    const [wasCreatedByAdmin, setWasCreatedByAdmin] = React.useState<boolean>(false)
    const [showToggleAutoRenewalModal, setShowToggleAutoRenewalModal] = React.useState<boolean>(false)
    const navigate = useNavigate()
    //- actions
    const isActivePackageAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/packageXAdvertisementXUser/hasUserActiveItemByPackageAdvertisement/${props.packageItem.id}/${props.advertisementId}`),
        headers: {
            Authorization: 'Bearer ' + props.jwtToken ?? '',
            Accept: 'application/json'
        }
    }
    const toggleAutoRenewalAction: Action = {
        method: 'PUT',
        endpoint: getApiUrlFromRelativeUrl(`/api/secured/packageXAdvertisementXUser/toggleAutoRenewal`),
        headers: {
            Authorization: `Bearer ${props.jwtToken ?? ''}`,
            Accept: 'application/json'
        },
        body: {
            packageId: props.packageItem.id,
            advertisementId: props.advertisementId
        }
    }
    //- context
    const clientContext = React.useContext(ClientContext)
    const authContext = React.useContext(AuthContext)
    //- effect for isActive
    React.useEffect(() => {
        const isActivePackage = async (): Promise<void> => {
            if (props.jwtToken !== null && props.jwtToken !== '') {
                const {error, payload, status} = await request(isActivePackageAction, clientContext)
                if (error) {
                    toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
                    setIsLoading(false)
                    return
                }
                if (status === 200) {
                    setIsActive(payload.hasAccess)
                    setHasActiveTill(payload.hasActiveTill)
                    setAutoRenewal(payload.autoRenewal)
                    setWasCreatedByAdmin(payload.createdByAdmin)
                }
            }
            setIsLoading(false)
        }
        isActivePackage()
    }, [])

    //- handlers
    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        setShowToggleAutoRenewalModal(true) // otvorime modalne okno
    }

    const handleConfirm = async (): Promise<void> => {
        const {error, payload, status} = await request(toggleAutoRenewalAction, clientContext)
        if (error && status === 500) {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
            return
        }
        if (status === 401) {
            await authContext.refreshJWTToken()
        }
        if (status === 200) {
            toast.success('Úspešne zmenené')
            setAutoRenewal(!autoRenewal)
        } else {
            toast.error(payload.errors['packageXAdvertisementXUser'])
        }
        setShowToggleAutoRenewalModal(false) // skryjeme modalne okno
    }

    return (
        <React.Fragment>
            <ConfirmModal
                show={showToggleAutoRenewalModal}
                title={autoRenewal ? 'Zrušenie predplatného' : 'Obnovenie predplatného'}
                message={autoRenewal ? 'Naozaj chcete zrušiť predplatné?' : 'Naozaj chcete obnoviť predplatné?'}
                onSuccessCallback={handleConfirm}
                onCloseCallback={() => setShowToggleAutoRenewalModal(false)}
            />
            {isLoading && <Loading/>}
            <Card sx={{border: 'none', boxShadow: 'none', marginTop: '5%'}}>
                <CardContent className='grey-background'
                             style={{borderRadius: '15px'}}>
                    <Container>
                        <Box display="flex" alignItems="center" justifyContent='center'>
                            <Typography variant="h6" className='text-primary' style={{fontFamily: 'OpenSansSemiBold'}}>
                                {props.packageItem.name}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent='center'>
                            <Typography variant="h6" className='text-primary' style={{fontFamily: 'OpenSansSemiBold'}}>
                                {props.packageItem.price === null ? 'Na mieru' : (
                                    <span>
                                        {props.packageItem.price?.price} {props.packageItem.price?.currency}
                                    </span>
                                )}
                            </Typography>
                        </Box>
                        <Box>
                            {props.features.map((feature: FeatureInterface) => (
                                <React.Fragment key={feature.id}>
                                    <Box display='flex' alignItems='center' justifyContent='start'>
                                        {props.packageItem.features.some(item => item.id === feature.id) ? (
                                            <CheckIcon className='text-success'/>
                                        ) : (
                                            <CloseIcon className='text-danger'/>
                                        )}
                                        <Typography variant="body1" style={{marginLeft: '5px'}}>
                                            <span className="text-center">
                                                {feature.name}
                                            </span>
                                        </Typography>
                                    </Box>
                                    {feature.id === props.features[props.features.length - 1].id ? '' : (
                                        <hr/>
                                    )}
                                </React.Fragment>
                            ))}
                        </Box>
                        {isActive && (
                            <React.Fragment>
                            <p style={{marginTop: '2%'}}>
                                <span className='text-primary' style={{fontFamily: 'OpenSansBold'}}>
                                    {autoRenewal ? 'Balík sa obnoví ' : 'Platný do'}:&nbsp;
                                </span>
                                <span>
                                    {hasActiveTill !== null ? dayjs(hasActiveTill, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm') : ''}
                                </span>
                            </p>
                            </React.Fragment>
                        )}
                        {isActive && (
                            <Box display="flex" alignItems="center" justifyContent='center' style={{marginTop: '2%'}}>
                                <button
                                    className={`${wasCreatedByAdmin ? 'btn-disabled' : 'background-primary border-primary'} rounded-borders `}
                                    style={{fontSize: '20px', fontFamily: 'OpenSansSemiBold'}}
                                    disabled={wasCreatedByAdmin}
                                    onClick={handleOnClick}
                                >
                                    {autoRenewal  ? 'Zrušiť predplatné' : 'Obnoviť predplatné'}
                                </button>
                            </Box>
                        )}
                        {!isActive && (
                            <Box display="flex" alignItems="center" justifyContent='center' style={{marginTop: '2%'}}>
                                <button
                                    className={`background-primary border-primary rounded-borders `}
                                    style={{fontSize: '20px', fontFamily: 'OpenSansSemiBold'}}
                                    onClick={() => navigate('/order/' + props.packageItem.id + '/' + props.advertisementId)}
                                >
                                    Zakúpiť
                                </button>
                            </Box>
                        )}
                    </Container>
                </CardContent>
            </Card>
        </React.Fragment>
    )
}
