import * as React from 'react'
import { Link } from 'react-router-dom'
import { Container, Typography } from "@mui/material"

export default function LoggedOutForInactivityPage(): JSX.Element {
    return (
        <React.Fragment>
            <Container sx={{ mt: '5%' }}>
                <Typography variant='h5' component='div' style={{ fontFamily: 'OpenSansBold'}} className='text-primary'>
                    Boli ste odhlásený z dôvodu neaktivity. Ak chcete pokračovať, prihláste sa prosím znovu.
                </Typography>
            </Container>
            <div className="w-100 text-center  mt-4">
                <Link
                    to='/login'
                    className='background-primary rounded-borders border-primary'
                    style={{textDecoration: 'none', fontFamily: 'OpenSansBold', fontSize: '22px', padding: '10px'}}
                >
                    Späť na prihlásenie
                </Link>
            </div>
        </React.Fragment>
    )
}
