import * as React from 'react'
import { useNavigate, useParams } from "react-router-dom"
import UserInterface from "../../types/User/UserInterface"
import { Action, ClientContext } from "react-fetching-library"
import UserViewProfileOrEditProfile from "../../components/user/UserViewProfileOrEditProfile"
import {getApiUrlFromRelativeUrl, request} from "../../utils/api/ApiUtil"
import { AuthContext } from "../../App"
import { Container, Typography } from "@mui/material"
import Loading from '../../components/loader/Loading'
import ErrorComponent from '../../components/error/ErrorComponent'

export default function UserViewProfile(): JSX.Element {
    // constants
    const COULDNT_LOAD_USER_MESSAGE: string = 'Nepodarilo sa načítať používateľa!'
    // context
    const clientContext = React.useContext(ClientContext)
    const authContext = React.useContext(AuthContext)
    // state
    const [user, setUser] = React.useState<UserInterface | null>(null)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [isError, setIsError] = React.useState<boolean>(false)
    // navigate
    const navigate = useNavigate()
    // init params
    const params = useParams()
    const { id } = params

    // actions
    const getUserAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/user/get/' + id),
        headers: {
            Authorization: 'Bearer ' + authContext.jwtToken,
            Accept: 'application/json'
        }
    }

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    React.useEffect(() => {
        const getUser = async (): Promise<void> => {
            const {payload, error, status} = await request(getUserAction, clientContext)
            if (error) {
                setIsError(true)
            } else {
                if (status === 200) {
                    setUser(payload)
                }
            }
            if (status === 401) {
                await authContext.refreshJWTToken()
            }
            setIsLoading(false)
        }
        getUser()
    } , [])


    if (isLoading) {
        return (
            <Loading/>
        )
    }

    if (isError) {
        return (
            <ErrorComponent message={COULDNT_LOAD_USER_MESSAGE}/>
        )
    }
    return (
        <React.Fragment>
            <Container sx={{mt: '5%', mb: '5%'}}>
                <Typography variant='h5' component='div' style={{fontFamily: 'OpenSansBold'}} className='text-primary'>
                    {user !== null ? `Detail použivateľa ''${user.isFO ? user.nameOfCompany : ((user.title ?? '') + ' ' + user.firstName + ' ' + user.lastName)}''` : ''}
                </Typography>
                {user !== null && (
                    <UserViewProfileOrEditProfile
                        userId={user.id}
                        isView={true}
                        messageOnSuccess={''}
                        messageOnError={''}
                        action={{method: 'GET', endpoint: ''}}
                        urlToRedirectToOnSuccess={''}
                    />
                )}
            </Container>
        </React.Fragment>
    )
}
