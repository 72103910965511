import * as React from 'react'
import { AuthContext } from "../../App"
import UserLoggedInterface from "../../types/User/UserLoggedInterface"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import Loading from '../loader/Loading'

export default function PrivateRoute(): JSX.Element {
    const authContext = React.useContext(AuthContext)
    const [userLogged, setUserLogged] = React.useState<UserLoggedInterface | null>(null)
    const [isUserLoggedIn, setIsUserLoggedIn] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const location = useLocation()

    React.useEffect(() => {
        const checkIfUserIsLoggedAndSetUserLogged = async () => {
            const isUserLoggedIn = await authContext.isUserSignedIn()
            const userLogged = await authContext.getUserLogged() // nastavi sa v contexte userLogged
            setIsUserLoggedIn(isUserLoggedIn)
            setUserLogged(userLogged)
            setIsLoading(false)
        }
        checkIfUserIsLoggedAndSetUserLogged()
    }, [authContext.jwtToken])

    if (isLoading) {
        return <Loading/>
    }

    return isUserLoggedIn ? <Outlet/> : <Navigate to='/login' state={{ from: location }} replace />
}
