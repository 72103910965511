import * as React from 'react'
import {Action, ClientContext} from 'react-fetching-library'
import {AuthContext} from '../../App'
import UserInterface from "../../types/User/UserInterface"
import {toast} from "react-toastify"
import {DataGrid, GridColDef, GridToolbarQuickFilter} from '@mui/x-data-grid'
import {useNavigate} from "react-router-dom"
import PaginationProps from "../../types/pagination/PaginationProps"
import {getApiUrlFromRelativeUrl, request} from "../../utils/api/ApiUtil"
import ConfirmModal from "../../components/modal/ConfirmModal"
import {Box, Container, IconButton, Pagination, Stack, Tooltip, Typography} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import DeleteIcon from "@mui/icons-material/Delete"
import EditAttributesIcon from '@mui/icons-material/EditAttributes'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import Loading from "../../components/loader/Loading"
import VisibilityIcon from "@mui/icons-material/Visibility"
import ErrorComponent from "../../components/error/ErrorComponent"
import my_profile_photo from "../../assets/icons/my_profile_photo.svg"
import PageSizeSelect from '../../components/pageSizeSelect/PageSizeSelect'

export default function UsersPage(): JSX.Element {
    // constants
    const INTERNAL_SERVER_ERROR_MESSAGE: string = 'Nastala neočakavaná chyba!'
    const USER_DELETED_SUCCESSFULLY: string = 'Používateľ bol úspešne vymazaný!'
    const USER_ENABLED_SUCCESSFULLY: string = 'Použivateľ bo úspešne aktivovaný!'
    // state
    const [users, setUsers] = React.useState<UserInterface[]>([])
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [page, setPage] = React.useState<number>(1)
    const [pageSize, setPageSize] = React.useState<number>(5)
    const [pagination, setPagination] = React.useState<PaginationProps>({
        pagination: [],
        total: 0,
        pagesCount: 0,
    })
    const [isError, setIsError] = React.useState<boolean>(false)
    const [rowCountState, setRowCountState] = React.useState<number>(
        pagination.pagination.length > 0 ? pagination.pagination.length : 0,
    )
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
    const [modalUser, setModalUser] = React.useState<UserInterface | null>(null)
    const [isModalEnableUserOpen, setIsModalEnableUserOpen] = React.useState<boolean>(false)

    //- effect
    React.useEffect(() => {
        setRowCountState(pagination.total)
    }, [pagination.total, setRowCountState])
    // context
    const authContext = React.useContext(AuthContext)
    const clientContext = React.useContext(ClientContext)
    // actions
    const getUsersAction: Action = {
        method: 'GET',
        endpoint: getApiUrlFromRelativeUrl('/api/user/getAll/' + pageSize + '/' + page),
        headers: {
            Authorization: 'Bearer ' + authContext.jwtToken,
            Accept: 'application/json'
        }
    }

    const deleteUserAction: Action = {
        method: 'DELETE',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/user/delete/'),
        headers: {
            Authorization: 'Bearer ' + authContext.jwtToken,
            Accept: 'application/json'
        }
    }

    const enableUserAction: Action = {
        method: 'PUT',
        endpoint: getApiUrlFromRelativeUrl('/api/secured/user/setEnabled'),
        headers: {
            Authorization: 'Bearer ' + authContext.jwtToken,
            Accept: 'application/json'
        }
    }

    // navigate
    const navigate = useNavigate()

    //- effect - enabled
    React.useEffect(() => {
        if (!authContext.userLogged?.enabled) {
            //- ak user nie je enabled, tak ho presmerujeme aby si aktivoval ucet
            navigate('/user/not-enabled')
        }
    }, [])

    React.useEffect(() => {
        if (!authContext.userLogged?.hasAccessToUserList) {
            navigate('/')
        }
    })

    React.useEffect(() => {
        getUsers()
    }, [page, pageSize])

    const getUsers = async (): Promise<void> => {
        setIsLoading(true)
        const {payload, error} = await request(getUsersAction, clientContext)
        if (error) {
            setIsError(true)
        } else {
            if (payload.success) {
                setUsers(payload.data.pagination)
                setPagination({
                    ...pagination,
                    pagesCount: payload.data.pagesCount === 0 ? 1 : payload.data.pagesCount,
                    total: payload.data.total,
                })
            }
        }
        setIsLoading(false)
    }

    const handleViewProfile = (id: number): void => {
        navigate('/user/view-profile/' + id)
    }

    const handleEditUser = (id: number) => {
        navigate('/edit-profile/' + id)
    }

    const handleDeleteUser2 = async (id: number): Promise<void> => {
        const {payload, error, status} = await request({
            ...deleteUserAction,
            endpoint: deleteUserAction.endpoint + id
        }, clientContext)
        if (error) {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
        } else {
            if (status === 204) {
                toast.success(USER_DELETED_SUCCESSFULLY)
                getUsers()
            } else {
                toast.error(payload.errors.user_delete)
            }
        }
        if (status === 401) {
            await authContext.refreshJWTToken()
        }
        setIsLoading(false)
    }

    const handleEnableUser = async (user: UserInterface): Promise<void> => {
        const {payload, status, error} = await request({
            ...enableUserAction,
            body: {
                userId: user.id,
                enabled: true
            },
        }, clientContext)
        if (error) {
            toast.error(INTERNAL_SERVER_ERROR_MESSAGE)
        } else {
            if (payload.success) {
                toast.success(USER_ENABLED_SUCCESSFULLY)
                setIsModalEnableUserOpen(false)
                setModalUser(null)
            } else {
                toast.error(payload.errors.user)
            }
        }
        if (status === 401) {
            await authContext.refreshJWTToken()
        }
        setIsLoading(false)
    }

    const askDeleteQuestion = (user: UserInterface) => {
        setModalUser(user)
        setIsModalOpen(true)
    }

    const askEnableQuestionUser = (user: UserInterface) => {
        setModalUser(user)
        setIsModalEnableUserOpen(true)
    }

    const hideModalAndClearModalUser = () => {
        setIsModalOpen(false)
        setModalUser(null)
    }

    const hideEnableUserModalAndClearModalUser = (): void => {
        setIsModalEnableUserOpen(false)
        setModalUser(null)
    }

    const handleDeleteUser = (user: UserInterface) => {
        handleDeleteUser2(user.id)
        hideModalAndClearModalUser()
    }

    const handleAddFeature = (user: UserInterface) => {
        navigate(`/addFeature/${user.id}`)
    }

    const handleAddPackage = (user: UserInterface) => {
        navigate(`/addPackage/${user.id}`)
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: '#',
            width: 70,
            renderCell: (params) => params.value
        },
        {
            field: 'avatar',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: '',
            width: 70,
            renderCell: (params) => params.value
        },
        {
            field: 'fullName',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Meno použivateľa',
            width: 300,
            renderCell: (params) => params.value
        },
        {
            field: 'email',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Email',
            width: 248,
            renderCell: (params) => params.value
        },
        {
            field: 'username',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Prihlasovacie meno',
            width: 200,
            renderCell: (params) => params.value,
            valueGetter: (params) => params.row.username
        },
        {
            field: 'actions',
            headerClassName: 'background-primary open-sans-bold',
            headerAlign: 'center',
            headerName: 'Akcie',
            width: 300,
            sortable: false,
            renderCell: (params) => params.value
        }
    ]

    const rows: any[] = []

    users.forEach((user: UserInterface) => {
        rows.push({
            id: user.id,
            avatar: (
                <img
                    src={(user.avatar !== null && user.avatar !== undefined) ? user.avatar.url !== null && user.avatar.url !== undefined ? getApiUrlFromRelativeUrl(user.avatar.url) : my_profile_photo : my_profile_photo}
                    width={32} height={32} alt={(user.title ?? '') + ' ' + user.firstName + ' ' + user.lastName} className='avatar' style={{border: '1px solid #fff', borderRadius: '50%', objectFit: 'contain'}} />
            ),
            fullName: !user.isFO ? user.nameOfCompany : ((user.title ?? '') + ' ' + user.firstName + ' ' + user.lastName),
            email: user.email,
            username: user.username,
            actions: (
                <React.Fragment>
                    <Tooltip title='Zobraziť profil'>
                        <IconButton aria-label="Zobraziť profil"
                                    onClick={() => handleViewProfile(user.id)}>
                            <VisibilityIcon className='icon'/>
                        </IconButton>
                    </Tooltip>
                    {!user.enabled && (
                        <Tooltip title='Aktivovať použivateľa'>
                            <IconButton aria-label="Aktivovať použivateľa"
                                        onClick={() => askEnableQuestionUser(user)}>
                                <VerifiedUserIcon className='icon'/>
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title='Upraviť použivateľa'>
                        <IconButton aria-label="Upraviť použivateľa"
                                    onClick={() => handleEditUser(user.id)}>
                            <EditIcon className='icon'/>
                        </IconButton>
                    </Tooltip>
                    {/* Poznamka od K: tu by sa asi malo pozerat na user!==userLogged,
                                       ale tu to mozem zanedbat, lebo server ta nemoze pustit zmazat sam seba
                     */}
                    <Tooltip title="Zmazať použivateľa">
                        <IconButton aria-label="Zmazať použivateľa"
                                    onClick={() => askDeleteQuestion(user)}
                        >
                            <DeleteIcon className='icon'/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Aktivovať vlastnosť pre zvoleného použivateľa a ponuku">
                        <IconButton aria-label="Aktivovať vlastnosť pre zvoleného použivateľa a ponuku"
                                    onClick={() => handleAddFeature(user)}
                        >
                            <EditAttributesIcon className='icon'/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title="Aktivovať balík pre zvoleného použivateľa a ponuku - automaticky aktivuje všetky vlastnosti balíka">
                        <IconButton
                            aria-label="Aktivovať balík pre zvoleného použivateľa a ponuku - automaticky aktivuje všetky vlastnosti balíka"
                            onClick={() => handleAddPackage(user)}
                        >
                            <AssignmentTurnedInIcon className='icon'/>
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            )
        })
    })

    if (isError) {
        return (
            <ErrorComponent
                message={INTERNAL_SERVER_ERROR_MESSAGE}
            />
        )
    }

    return (
        <React.Fragment>
            {isLoading && <Loading/>}
            {/* enable user modal */}
            <ConfirmModal
                show={isModalEnableUserOpen}
                onCloseCallback={() => hideEnableUserModalAndClearModalUser()}
                onSuccessCallback={() => handleEnableUser(modalUser as UserInterface)}
                message={`Naozaj chcete aktivovať používateľa ${modalUser?.title ?? ''} ${modalUser?.firstName} ${modalUser?.lastName}?`}
                title={'Aktivovať používateľa'}
            />
            {/* delete user modal */}
            <ConfirmModal
                show={isModalOpen}
                onCloseCallback={() => hideModalAndClearModalUser()}
                onSuccessCallback={() => handleDeleteUser(modalUser as UserInterface)}
                message={`Naozaj chcete vymazať používateľa ${modalUser?.title ?? ''} ${modalUser?.firstName} ${modalUser?.lastName}?`}
                title='Vymazať používateľa'
            />
            <Container sx={{mt: '5%', mb: '2%'}}>
                <Typography variant='h5' component='div' style={{fontFamily: 'OpenSansBold', marginBottom: '2%'}}
                            className='text-primary'>
                    Zoznam použivateľov
                </Typography>
                <button className='background-primary rounded-borders primary-border mb-4'
                        onClick={() => navigate('/add-user')}
                        style={{padding: '5px'}}
                >
                    <AddIcon/>
                    <span style={{fontFamily: 'OpenSansSemiBold'}}>
                        Pridať používateľa
                    </span>
                </button>
                <DataGrid
                    rowCount={rowCountState}
                    disableRowSelectionOnClick={true}
                    disableColumnMenu={true}
                    columns={columns}
                    loading={isLoading}
                    paginationMode='server'
                    pageSizeOptions={[5, 10, 25]}
                    rows={rows}
                    disableColumnSelector
                    disableDensitySelector
                    hideFooter
                    hideFooterPagination
                    hideFooterSelectedRowCount={true}
                    density={"standard"}
                    slots={{
                        toolbar: GridToolbarQuickFilter,
                        loadingOverlay: Loading,
                    }}
                />
                <Stack direction="row" pt={2}>
                    <Box flex={1}>
                        {users && (
                            <Typography variant="subtitle2">
                                Počet najdených záznamov: {pagination.total}
                            </Typography>
                        )}
                    </Box>
                    <Pagination
                        count={pagination.pagesCount}
                        page={page}
                        siblingCount={3}
                        onChange={(e, value) => setPage(value)}
                    />
                    <Box flex={1}/>
                    <PageSizeSelect
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                    />
                </Stack>
            </Container>
        </React.Fragment>
    )
}
